/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { CONSTANT_ROUTE } from 'utils/constants';

export const ProtectedRoutes = (props: any) => {
    const { isLoggedIn } = useAppSelector((state) => state.reducer.user);
    return isLoggedIn ? <Outlet /> : <Navigate to='/login' />;
};

export const PublicRoutes = (props: any) => {
    const { isLoggedIn } = useAppSelector((state) => state.reducer.user);
    return isLoggedIn ? <Navigate to={`/${CONSTANT_ROUTE.SELECT_VEHICLE}`} /> : <Outlet />;
};
