import { createApi } from '@reduxjs/toolkit/query/react';
import { IResponseGetContainers, IParamsGetGeneralContainers, ParamsContainer, IResponseGetGeneralContainers } from 'models';
import customFetchBase from './customFetchBase';

export const ContainersApi = createApi({
    reducerPath: 'ContainersApi',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        getContainersType: builder.query<any, ParamsContainer>({
            query: (params) => ({
                url: `/v1/containers/types`,
                params,
                method: 'GET',
            }),
        }),

        getContainers: builder.query<IResponseGetContainers, ParamsContainer>({
            query: (params) => ({
                url: `/v1/containers`,
                params,
                method: 'GET',
            }),
        }),

        getGeneralContainers: builder.query<IResponseGetGeneralContainers, IParamsGetGeneralContainers>({
            query: (params) => ({
                url: `/v1/containers/generalcontainers`,
                params,
                method: 'GET',
            }),
        }),

        getContainerByCd: builder.query<any, { containerCd: string }>({
            query: ({ containerCd }) => ({
                url: `/v1/containers/${containerCd}`,
                method: 'GET',
            }),
        }),

        getContainerTypeByCd: builder.query<any, { typeCd: string }>({
            query: ({ typeCd }) => ({
                url: `/v1/containers/types/${typeCd}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useLazyGetContainersTypeQuery, useLazyGetContainersQuery, useLazyGetGeneralContainersQuery, useLazyGetContainerByCdQuery, useLazyGetContainerTypeByCdQuery } = ContainersApi;
