import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
    SETTING_WHEN_SELECT_VEHICLE,
    COLLECTION_RESULT_INPUT_SETTING,
    IMPORT_RECORD_REGISTRAION_SETTING,
    WORK_LINE_VOUCHER_SETTING,
    SORT_TYPE,
    LOCAL_STORAGE,
    SPOT_WORK_DATE_TYPE,
    SETTING_SEARCH_DISPATCH_STATUS,
    SETTING_PRODUCT_TYPE,
    DEFAULT_COLOR,
} from 'utils/constants';
import { applyColorSystem } from 'utils/functions';

interface ISystemSetting {
    defaultColor?: 'green' | 'purple';
    inputConfirmMessageFlg: boolean;
    settingWhenSelectVehicle: number;
    settingProductType: number;
    collectionResultInputSetting: {
        course: number;
        spot: number;
    };
    commonPageSize: number,
    importRecordRegistrationSetting: number;
    workLineVoucherSetting: number;
    screenDisplay: {
        intervalAutoUpdate: number;
    };
    location: {
        intervalAutoUpdate: number;
    };

    searchDispatchStatusDefault: {
        settingSearch: number | null;
        spotInfoIsDisplay: boolean;
        spotDispatchStatusIsReceived: boolean;
        spotDispatchStatusIsDispatch: boolean;
        spotDispatchStatusIsRecorded: boolean;
        spotDispatchStatusIsCancel: boolean;
        spotDispatchStatusIsNoCollection: boolean;
        isSearchSpotWorkDate: boolean | null;
        spotWorkDateType: number | null;

        spotBranchCd: number | null;
        spotBranchName: string;

        spotSalesPersonCd: number | null | any;
        spotSalesName: string;

        spotDriverCd: number | null | any;
        spotDriverName: string;

        spotVehicleTypeCd: number | null | any;
        spotVehicleTypeName: string;

        spotCollectionPlace: string;
        spotCollectionPlaceType: number;

        periodicDispatchInfoIsDisplay: boolean;
        isSearchPeriodicSagyouDate: boolean;

        periodicBranchCd: number | null;
        periodicBranchName: string;

        periodicDriverCd: number | null | any;
        periodicDriverName: string;

        periodicVehicleTypeCd: number | null | any;
        periodicVehicleTypeName: string;

        sortOrderPriority1Cd: number | null;
        sortOrderPriority1Name: string;
        sortOrderPriority1Type: number | null;
        sortOrderPriority2Cd: number | null;
        sortOrderPriority2Name: string;
        sortOrderPriority2Type: number | null;
        sortOrderPriority3Cd: number | null;
        sortOrderPriority3Name: string;
        sortOrderPriority3Type: number | null;
        sortOrderPriority4Cd: number | null;
        sortOrderPriority4Name: string;
        sortOrderPriority4Type: number | null;
        sortOrderPriority5Cd: number | null;
        sortOrderPriority5Name: string;
        sortOrderPriority5Type: number | null;

        spotWorkDate: string;
        periodicSagyouDate: string;
    };
    productDefault: any[];
}
export const DEFAULT_SYSTEM_SETTING = {
    defaultColor: DEFAULT_COLOR.GREEN,
    // 入力確認メッセージ設定
    inputConfirmMessageFlg: true,
    // 車輌選択時設定
    settingWhenSelectVehicle: SETTING_WHEN_SELECT_VEHICLE.PRIORITIZE_LAST_USED_VEHICLE,
    settingProductType: SETTING_PRODUCT_TYPE.DEFAULT,
    collectionResultInputSetting: {
        // コース
        course: COLLECTION_RESULT_INPUT_SETTING.COURSE.PRIORITIZE_QUANTITY_INPUT,
        // スポット
        spot: COLLECTION_RESULT_INPUT_SETTING.SPOT.PRIORITIZE_QUANTITY_INPUT,
    },
    commonPageSize: 5,
    // 搬入実績登録設定
    importRecordRegistrationSetting:
        IMPORT_RECORD_REGISTRAION_SETTING.CARRIED_TO_EACH_COLLECTION_SITE,
    // 作業明細伝票設定
    workLineVoucherSetting: WORK_LINE_VOUCHER_SETTING.DISPLAY_PRINT,
    // 画面表示自動更新設定（リロード）- 更新間隔（秒）
    screenDisplay: {
        intervalAutoUpdate: 120,
    },
    // 位置情報の更新設定 - 更新間隔（秒）
    location: {
        intervalAutoUpdate: 120,
    },
    // Vehicle dispatch status search default settings
    // 検索条件/絞り込み/並び順の保持設定
    searchDispatchStatusDefault: {
        settingSearch: SETTING_SEARCH_DISPATCH_STATUS.PRIORITIZE_PREVIOUS_SEARCH,
        spotInfoIsDisplay: true,
        spotDispatchStatusIsReceived: false,
        spotDispatchStatusIsDispatch: false,
        spotDispatchStatusIsRecorded: false,
        spotDispatchStatusIsCancel: false,
        spotDispatchStatusIsNoCollection: false,
        isSearchSpotWorkDate: false,
        spotWorkDateType: SPOT_WORK_DATE_TYPE.MATCH,

        spotBranchCd: null,
        spotBranchName: '',

        spotSalesPersonCd: null,
        spotSalesName: '',

        spotDriverCd: null,
        spotDriverName: '',

        spotVehicleTypeCd: null,
        spotVehicleTypeName: '',

        spotCollectionPlace: '',
        spotCollectionPlaceType: 0,

        periodicDispatchInfoIsDisplay: true,
        isSearchPeriodicSagyouDate: false,

        periodicBranchCd: null,
        periodicBranchName: '',

        periodicDriverCd: null,
        periodicDriverName: '',

        periodicVehicleTypeCd: null,
        periodicVehicleTypeName: '',

        sortOrderPriority1Cd: null,
        sortOrderPriority1Name: '',
        sortOrderPriority1Type: SORT_TYPE.ASCENDING,
        sortOrderPriority2Cd: null,
        sortOrderPriority2Name: '',
        sortOrderPriority2Type: SORT_TYPE.ASCENDING,
        sortOrderPriority3Cd: null,
        sortOrderPriority3Name: '',
        sortOrderPriority3Type: SORT_TYPE.ASCENDING,
        sortOrderPriority4Cd: null,
        sortOrderPriority4Name: '',
        sortOrderPriority4Type: SORT_TYPE.ASCENDING,
        sortOrderPriority5Cd: null,
        sortOrderPriority5Name: '',
        sortOrderPriority5Type: SORT_TYPE.ASCENDING,

        spotWorkDate: dayjs(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD'),
        periodicSagyouDate: dayjs(new Date().setDate(new Date().getDate() + 1)).format(
            'YYYY-MM-DD',
        ),
    },
    // 受入実績入力 品名既定値設定
    productDefault: [],
};

const cacheSystemSetting: ISystemSetting =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE.KEY_SYSTEM_SETTING)) || null;

const systemSettingSlice = createSlice({
    name: 'systemSettingSlice',
    initialState: {
        systemSetting: cacheSystemSetting || DEFAULT_SYSTEM_SETTING,
    },
    reducers: {
        saveSystemSetting: (state, action) => {
            state.systemSetting = JSON.parse(JSON.stringify(action.payload));
            localStorage.setItem(LOCAL_STORAGE.KEY_SYSTEM_SETTING, JSON.stringify(action.payload));
            // Change color system
            applyColorSystem(action.payload?.defaultColor);
        },
    },
});

export const { saveSystemSetting } = systemSettingSlice.actions;
export const SystemSettingReducer = systemSettingSlice.reducer;
