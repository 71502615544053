import { createApi } from '@reduxjs/toolkit/query/react';
import {
    CollectSummary, ICollectionPrint, Collection,
    ParamCollection, ParamsCollectSummary, ResCollectionBySeqNo, ICollection, ICollectionSiteInfo,
} from 'models';
import {
    IResponseGetCoursesSuccess,
    IParamsRequestCollectionCourses,
    IParamsRequestCollectionCoursesByCd,
} from 'models/course';
import { IResponseGetSpotsSuccess, IParamsRequestCollectionSpots } from 'models/spot';
import { createSlice } from '@reduxjs/toolkit';
import customFetchBase from './customFetchBase';

export const CollectionApi = createApi({
    reducerPath: 'CollectionApi',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        getCollectionSummary: builder.query<CollectSummary, ParamsCollectSummary>({
            query: (params) => ({
                url: `/v1/collections/summary`,
                params,
                method: 'GET',
            }),
        }),

        getCollectionBySeqNo: builder.query<ICollection, { seqNo: number }>({
            query: ({ seqNo }) => ({
                url: `/v1/collections/${seqNo}`,
                method: 'GET',
            }),
        }),

        getSignatureCollection: builder.query<any, { seqNo: number }>({
            query: ({ seqNo }) => ({
                url: `/v1/collections/${seqNo}/signature`,
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
        }),

        getNoteCollection: builder.query<any, { seqNo: number }>({
            query: ({ seqNo }) => ({
                url: `/v1/collections/${seqNo}/drivermemo`,
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
        }),

        getCollectionSiteInfo: builder.query<ICollectionSiteInfo, { seqNo: number }>({
            query: ({ seqNo }) => ({
                url: `/v1/collections/${seqNo}/siteinfo`,
                method: 'GET',
            }),
        }),

        updateCollectionBySeqNo: builder.mutation<any, { seqNo: number; body: any }>({
            query: ({ seqNo, body }) => ({
                url: `/v1/collections/${seqNo}/quantity`,
                body,
                method: 'PUT',
                responseHandler: (response) => response.blob(),
            }),
        }),

        getCollectionSpots: builder.query<IResponseGetSpotsSuccess, IParamsRequestCollectionSpots>({
            query: (params: IParamsRequestCollectionSpots) => ({
                url: '/v1/collections/spots',
                params,
                method: 'GET',
                credentials: 'include',
            }),
        }),

        getCollectionCourses: builder.query<
            IResponseGetCoursesSuccess,
            IParamsRequestCollectionCourses
        >({
            query: (params: IParamsRequestCollectionCourses) => ({
                url: '/v1/collections/courses',
                params,
                method: 'GET',
                credentials: 'include',
            }),
        }),

        getCollectionCoursesByCd: builder.query<
            IResponseGetCoursesSuccess,
            IParamsRequestCollectionCoursesByCd
        >({
            query: (params: IParamsRequestCollectionCoursesByCd) => ({
                url: `/v1/collections/courses/${params.courseNameCd}`,
                params,
                method: 'GET',
                credentials: 'include',
            }),
        }),

        getInfoCollection: builder.query<Collection, ParamCollection>({
            query: ({ seqNo }) => ({
                url: `/v1/collections/${seqNo}/info`,
                method: 'GET',
            }),
        }),

        updateExclusionStatusBySeqNo: builder.mutation<any, { seqNo: number; body: any }>({
            query: ({ seqNo, body }) => ({
                url: `/v1/collections/${seqNo}/exclusionstatus`,
                body,
                method: 'PUT',
            }),
        }),

        getCollectionContainer: builder.query<any, { seqNo: number }>({
            query: ({ seqNo }) => ({
                url: `/v1/collections/${seqNo}/containers`,
                method: 'GET',
            }),
        }),

        getCollectionPrintInfo: builder.query<ICollectionPrint, { seqNo: string | number }>({
            query: ({ seqNo }) => ({
                url: `/v1/collections/${seqNo}/printinfo`,
                method: 'GET',
            }),
        }),
        getContainersByContainerSeqNo: builder.query<
            any,
            { seqNo: number; containerSeqNo: number; recordNo: number }
        >({
            query: (params) => ({
                url: `/v1/collections/${params.seqNo}/containers/${params.containerSeqNo}/${params.recordNo}`,
                method: 'GET',
            }),
        }),

        postContainers: builder.mutation<any, { seqNo: number; body: any }>({
            query: ({ seqNo, body }) => ({
                url: `/v1/collections/${seqNo}/containers`,
                body,
                method: 'POST',
            }),
        }),

        putContainers: builder.mutation<
            any,
            { seqNo: number; containerSeqNo: number; recordNo: number; body: any }
        >({
            query: ({ seqNo, containerSeqNo, recordNo, body }) => ({
                url: `/v1/collections/${seqNo}/containers/${containerSeqNo}/${recordNo}`,
                body,
                method: 'PUT',
            }),
        }),

        putCollectionReports: builder.mutation<any, number[]>({
            query: (body) => ({
                url: `/v1/collections/reports/dispatch`,
                body,
                method: 'PUT',
            }),
        }),
    }),
});

const collectionSlice = createSlice({
    name: 'collectionSlice',
    initialState: {
        course: {
            isPrioritizeQuantityInput: null,
        },
        spot: {
            isPrioritizeQuantityInput: null,
            searchConditions: null,
            isDisplayUnregisteredContainer: true,
        },
    },
    reducers: {
        setCourseIsPrioritizeQuantityInput: (state, action) => {
            state.course.isPrioritizeQuantityInput = action.payload;
        },
        setSpotIsPrioritizeQuantityInput: (state, action) => {
            if (state.spot) {
                state.spot.isPrioritizeQuantityInput = action.payload;
            }

        },

        setSpotIsDisplayUnregisteredContainer: (state, action) => {
            if (state.spot) {
                state.spot.isDisplayUnregisteredContainer = action.payload;
            }
        },

        setSpotSearchConditions: (state, action) => {
            if (state.spot) {
                state.spot.searchConditions = action.payload;
            }
        },

        clearSpotSearchConditions: (state) => {
            if (state.spot) {
                state.spot.searchConditions = null;
            }
        },
    },
});

export const {
    setCourseIsPrioritizeQuantityInput,
    setSpotIsPrioritizeQuantityInput,
    setSpotSearchConditions,
    clearSpotSearchConditions,
    setSpotIsDisplayUnregisteredContainer,
} = collectionSlice.actions;
export const CollectionReducer = collectionSlice.reducer;

export const {
    useLazyGetCollectionSummaryQuery,
    useGetCollectionBySeqNoQuery,
    useLazyGetCollectionBySeqNoQuery,
    useUpdateCollectionBySeqNoMutation,
    useLazyGetCollectionSpotsQuery,
    useLazyGetCollectionCoursesQuery,
    useLazyGetCollectionCoursesByCdQuery,
    useLazyGetInfoCollectionQuery,
    useUpdateExclusionStatusBySeqNoMutation,
    useGetCollectionContainerQuery,
    useLazyGetCollectionPrintInfoQuery,
    useLazyGetContainersByContainerSeqNoQuery,
    usePostContainersMutation,
    usePutContainersMutation,
    useLazyGetSignatureCollectionQuery,
    usePutCollectionReportsMutation,
    useLazyGetCollectionContainerQuery,
    useLazyGetNoteCollectionQuery,
    useLazyGetCollectionSiteInfoQuery,
} = CollectionApi;
